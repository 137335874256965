<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Name <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.bankName"
                  :rules="[v => !!v || 'Bank Name is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Branch<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.branch"
                  :rules="[v => !!v || 'Branch is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Owner Name<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.ownerName"
                  :rules="[v => !!v || 'Owner is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Swift Code<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.swiftCode"
                  :rules="[v => !!v || 'Swift Code is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Address<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.address"
                  :rules="[v => !!v || 'Address is required']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Default</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-switch v-model="form.defaultSetting" label="Default"></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" v-on="on" @click="add">
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Add Account Number</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Account Number<span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.items"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.accountNumber="{ item }">
                          <v-text-field
                            outlined
                            flat
                            dense
                            background-color="white"
                            v-model="item.accountNumber"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.action="{ index, item }">
                          <v-btn
                            color="error"
                            @click="deleteItem(index, item)"
                            v-if="!item.deleted"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                          <v-btn color="primary" @click="undo(index)" v-if="item.deleted">
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "form-template-bank",
  props: {
    form: Object,
  },
  data: () => ({
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
  }),

  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        this.$store
          .dispatch("templateBank/create", { form: this.form, type: "update" })
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    add() {
      this.form.items.push({
        accountNumber: "",
      });
    },
    deleteItem(index, item) {
      if (item.id) {
        this.form.items[index].deleted = true;
      } else {
        this.form.items.splice(index, 1);
      }
    },
    undo(index) {
      this.form.items[index].deleted = false;
    },
  },
};
</script>

<style></style>
